<template>
  <div class="mysymptoms-main-container">

    <v-layout>
      <v-flex xs8>
        <h1 class="display-1 pt-3">Patients</h1>
      </v-flex>
      <v-flex xs4>
        <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-flex>
    </v-layout>
    <!--    <v-layout v-if="this.patientsLoaded && this.patients.length == 0">-->
    <!--      <p>New to mySymptoms Clinic? Check out our-->
    <!--        <a-->
    <!--            href="https://mysymptoms.zendesk.com/hc/en-us/articles/5655387160594-mySymptoms-Clinic-Getting-Started-Guide"-->
    <!--            target="_blank">-->
    <!--          Getting Started Guide-->
    <!--        </a>.-->
    <!--      </p>-->
    <!--    </v-layout>-->

    <v-card>
      <!-- main data table -->
      <!-- Note, we are not using the v-data-table built in search. Instead, we are pre-filtering the items so we can
      search on the displayed Patient Since date string-->
      <!-- pagination https://github.com/vuetifyjs/vuetify/issues/442 -->
      <v-data-table
          :headers="headers"
          :items="filteredPatients"
          must-sort
          no-data-text="No patients found."
          no-results-text="No patients found."
          v-bind:pagination.sync="pagination"
          v-bind:rows-per-page-items="rowsPerPageItems"
      >
        <template slot="headerCell" slot-scope="props">
          <span v-if="!('tooltip' in props.header) || props.header.tooltip === ''">{{ props.header.text }}</span>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ props.header.text }}</span>
            </template>
            <span>{{ props.header.tooltip }}</span>
          </v-tooltip>
        </template>

        <template slot="items" slot-scope="props"
        >
          <router-link
              tag="tr"
              :to="{name: 'patient_diary', params: { patientUsername: props.item.username, clinicianUsername: clinicianUsername }}"
              class="black--text cursor_pointer"
              v-bind:class="{disabled: isRowDisabled(filteredPatients.indexOf(props.item))}"
          >
            <td class="justify-left">
              {{ props.item.username }}
            </td>
            <td>
              {{ props.item.realName }}
            </td>
            <td>{{ props.item.patientSince }}</td>
            <td>{{ props.item.lastUpdated }}</td>
            <td class="justify-center px-24 enabled">
              <!--              only show the 'edit realname' button if the name wasn't got from the database (backwards compatibility)-->
              <v-dialog
                  v-if="!props.item.isRealNameSetFromDb"
                  width="400"
                  v-model="showEditRealNameDialogue[props.item.username]"
              >
                <v-btn icon slot="activator">
                  <v-tooltip bottom>
                    <v-icon slot="activator">
                      edit
                    </v-icon>
                    <span>Edit Real Name</span>
                  </v-tooltip>
                </v-btn>
                <v-card>
                  <v-card-title
                      class="headline grey lighten-2"
                      primary-title
                  >
                    Edit Real Name
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-text-field
                          slot="input"
                          v-model="props.item.realName"
                          :rules="[max25chars]"
                          label="Name"
                          single-line
                          autofocus
                          @keyup.enter="saveRealName(props.item)"
                      >
                      </v-text-field>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        flat
                        @click="saveRealName(props.item)"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                  v-model="confirmDeleteDialog[props.item.username]"
                  width="500"
              >
                <!-- note: clicking on the activator slot opens the encompassing v-dialog -->
                <v-btn icon slot="activator">
                  <v-tooltip bottom>
                    <v-icon slot="activator">
                      delete
                    </v-icon>
                    <span>Remove from patient list</span>
                  </v-tooltip>
                </v-btn>

                <v-card>
                  <v-card-title
                      class="headline grey lighten-2"
                      primary-title
                  >
                    Confirm
                  </v-card-title>
                  <v-card-text>
                    Are you sure you want to remove {{ props.item.username }} from your patient list?
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        flat
                        @click="confirmDeleteDialog[props.item.username] = false"
                    >
                      No
                    </v-btn>
                    <v-btn
                        color="primary"
                        flat
                        @click="deleteItem(props.item)"
                    >
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            </td>
          </router-link>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import ClinicianAccountService from '../utils/clinician-account-service'
import Logger from '../utils/logger-utils'
import {checkProperties} from '../utils/helpers'
import Cookies from 'js-cookie'
import VEditDialog from "vuetify/es5/components/VDataTable/VEditDialog";
import Loader from "../utils/loader-utils"
import moment from 'moment'
import VDialog from "vuetify/lib/components/VDialog/VDialog";
import RealNameHelpers from '../utils/real-name-helpers'


export default {
  name: "PatientManagement",
  components: {VDialog},
  data() {
    return {
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      headers:
          [
            {text: 'Patient Username', value: 'username', sortable: true},
            {
              text: 'Name',
              value: 'realName',
              sortable: true,
              searchable: true
            },
            // note we're sorting on the numeric version of the Patient Since but displaying the text version
            {text: 'Patient Since', value: 'patientSinceSortable', sortable: true},
            {text: 'Last Updated', value: 'lastUpdatedSortable', sortable: true},
            {text: 'Actions', value: 'actions', sortable: false},
          ],

      patients: [],
      patientsLoaded: false,
      search: '',
      pagination: {'sortBy': 'patientSinceSortable', 'descending': true, 'rowsPerPage': 25},
      rowsPerPageItems: [10, 25, 50, {text: 'All', value: -1}],
      confirmDeleteDialog: {},
      showEditRealNameDialogue: {}
    }
  },

  props: {
    clinicianUsername: {
      type: String,
      required: true
    },
  },

  methods: {

    // when we're on a plan with a limited number clients, the clinician may have downgraded from a plan with
    // more clients. In that case disable access to a number of patients that exceed the limit for this plan
    // OR
    // if the account is paused, disable them all
    isRowDisabled(row_cnt) {
      if (this.$store.state.plan.status === 'paused') {
        return true
      } else if (!this.$store.state.plan.numClients) {
        // numClients not defined so we are on an unlimited plan
        return false
      } else {
        return row_cnt >= this.$store.state.plan.numClients
      }
    },

    saveRealName(patient) {
      RealNameHelpers.saveRealNameToLocalStorage(patient.username, patient.realName)
      this.showEditRealNameDialogue[patient.username] = false
    },

    deleteItem(patient) {
      this.confirmDeleteDialog[patient.username] = false
      Loader.start()
      ClinicianAccountService.deletePatient(this.$store.state.username, patient.username).then(() => {
        // remove the patient from the screen so we don't have to do a reload of the from the server
        const idx = this.patients.indexOf(patient)
        if (idx < 0) {
          throw new Error(`Could not find patient: ${JSON.stringify(patient)}`)
        }
        this.patients.splice(idx, 1)

        // remove the cookie containing the real name
        Cookies.remove(patient.username)
        Loader.stop()
      }).catch(err => {
        Logger.error(err)
        Loader.stop()
      })
    }
  },
  computed: {
    /**
     * filters the patient records by searching ** only ** the string fields of the row. The row is included if any
     * field value contains the search text (case insensitive)
     */
    filteredPatients() {
      const search = this.search.toString().toLowerCase()
      if (search.trim() === '') {
        return this.patients
      }

      const ret = this.patients.filter(patient => {
        // loop over each member of the object, return true as soon as we find a field that contains the search string
        for (let [, value] of Object.entries(patient)) {
          if (typeof value === "string" && value.toLowerCase().includes(search)) {
            return true
          }
        }
        // we didn't find any occurrence of the search string so return false
        return false
      })
      return ret
    },
  },
  mounted() {
    Loader.start()
    ClinicianAccountService.getPatients(this.clinicianUsername).then(resp => {
      checkProperties(resp, 'data')


      const tempData = resp.data.map(patient => {

        checkProperties(patient, ['patient', 'patientSince'])

        // check to see if we have stored a name for this user in a cookie, note names are stored encoded
        // noinspection JSUnresolvedVariable
        const patientName = patient.patient

        // in v0.8: the real name is now coming from the database, but to maintain backwards compatibility, if they
        // have patients who shared their diary before v0.8 was released, their real name maybe stored in cookies
        const firstNameFromDb = patient.firstName == null ? "" : patient.firstName
        const lastNameFromDb = patient.lastName == null ? "" : " " + patient.lastName
        // a constraint of the diary sharing is that both first and last name fields must be populated
        const realNameFromDb = firstNameFromDb + lastNameFromDb;
        const isRealNameSetFromDb = !(realNameFromDb === "")
        // if the realNameFromDb is empty, then look to see if there is anything in local storage
        const realName = !isRealNameSetFromDb ? RealNameHelpers.getRealNameFromLocalStorage(patientName) :
            realNameFromDb

        const sinceDate = moment(patient.patientSince)
        const lastUpdated = patient.latestSyncObject ? moment(patient.latestSyncObject) : null

        return {
          username: patientName,
          realName: realName ? realName : '',
          isRealNameSetFromDb: isRealNameSetFromDb,
          patientSince: sinceDate.format("DD MMM YYYY"),
          patientSinceSortable: sinceDate.valueOf(), // we need a version of the 'since' field for sorting on
          lastUpdated: lastUpdated ? lastUpdated.format("DD MMM YYYY") : '',
          lastUpdatedSortable: lastUpdated ? lastUpdated.valueOf() : 0,
          readOnly: false
        }

      })
      // we need to make sure the data is sorted by the default sort order, since if some of rows are disabled we need
      // to make sure it is the first few rows are enable (note rows are disabled if the user has downgraded from
      // thei subscription plan and has too many patients for their current plan
      // this.patients = _.orderBy(tempData, ["patientSinceSortable", 'desc']);
      this.patients = _.orderBy(tempData, ["patientSinceSortable"], ['desc'])
      this.patientsLoaded = true
      Loader.stop()
    }).catch(err => {
      Loader.stop()
      Logger.error(err)
    })

  },
}
</script>

<style scoped>
.cursor_pointer {
  cursor: pointer;
  height: 42px;
  width: 42px;
}

/*https://stackoverflow.com/questions/61396466/how-to-disable-a-vuejs-router-link*/
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.enabled {
  opacity: 1;
  pointer-events: auto;
}
</style>
