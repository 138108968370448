<template>
  <div id="app"
       @click="logActivity" @mousemove="logActivity" @keydown="logActivity"
       @contextmenu="logActivity">

    <v-app id="mySymptomsVuetify" class="myFont">
      <!-- displays the loading icon for long running operations-->
      <Loader></Loader>

      <!--side navigation-->
      <!--      temporary-->
      <v-navigation-drawer
          app
          width="220"
          :mini-variant.sync="mini"
          permanent
          clipped
          v-if="signedIn && isClinician"
          class="secondary nav-drawer"
      >
        <v-layout style='height: 100%' column justify-space-between>
          <v-list>
            <!-- can't get class=grey--ligthen--4 to work so using a inline style as a work around for the
             time being -->
            <!--            :to="{name: item.to}"-->
            <v-list-tile>
              <v-list-tile-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        outline
                        @click.stop="mini=!mini"
                        color="white"
                        v-on="on"
                    >
                      <v-icon v-if="mini">chevron_right</v-icon>
                      <v-icon v-else>chevron_left</v-icon>
                    </v-btn>
                  </template>
                  <span>Open/Close Navigation</span>
                </v-tooltip>
              </v-list-tile-action>
            </v-list-tile>
            <v-list-tile v-for="item in navItems"
                         :key="item.title"
                         :to="item.to"
                         active-class="white--text"
                         class="nav-drawer-tile"
            >
              <v-list-tile-action>
                <img alt="Nav Icon" :src="item.icon" class="nav-icon">
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="font-size: 16px"
                >{{ item.title }}
                  <span style="color: orangered">{{ item.alert }}</span>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
                @click="replayIntro"
                active-class="white--text"
                class="nav-drawer-tile"
                v-if="hasPatients===false"
            >
              <v-list-tile-action>
                <img alt="Nav Icon" src="./assets/subscriptions_white_48dp.svg" class="nav-icon">
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="font-size: 16px"
                >Replay Intro
                  <!--                  <span style="color: orangered">{{ item.alert }}</span>-->
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>

          <span v-if="!mini">
            <img alt="Skygazer Logo" style="align-self: center" src="./assets/skygazer_logo_150.png" width="150"/>
          </span>
        </v-layout>

      </v-navigation-drawer>

      <!-- toolbar running across the top of the app-->
      <v-toolbar
          app
          clipped-left
          clipped-right
          flat
          color="primary"
          style="color: white">

        <!-- logo-->
        <!--suppress CheckImageSize -->
        <img v-if="isPatientRequest" alt="mySymptoms Logo" src="./assets/white_mysymptomsLogo.png"
             style="padding-top: 3px; padding-right: 5px" height="43"/>
        <router-link v-else :to="{name: 'home'}">
          <!--suppress CheckImageSize -->
          <!--          <img alt="mySymptoms Logo" src="./assets/clinic_logo_full_white.png" style="padding-top: 3px" height="43"/>-->
          <img alt="mySymptoms Logo" style="padding-bottom: 5px; padding-top: 5px; padding-right: 5px"
               src="./assets/clinic_logo_full_white.png" height="52"/>
        </router-link>

        <span v-if="showMode">
                    Mode: {{ mySymptomsMode }}
        </span>

        <!--        <span name="beta_trial_badge" class="beta_badge" v-if="inBetaTrial && !isPatientRequest">-->
        <!--          during rollout -->
        <!--                    [Beta]-->
        <!--        </span>-->
        <span v-if="showMode" style="padding-left: 3px;">
                          Plan: {{ this.$store.state.plan.displayName }}, Status: {{ this.$store.state.plan.status }},
                          Num Clients: {{ this.$store.state.plan.numClients }}
        </span>
        <span v-if="this.isSubscriptionPaused()" class="header_warning">
          Warning: Your <router-link :to="{name: 'my_subscription'}"> subscription </router-link> is Paused.
        </span>
        <span v-if="this.isPastDue()" class="header_warning">
          Warning: We have been unable to take payment for your
          <router-link :to="{name: 'my_subscription'}"> subscription </router-link>
        </span>

        <v-spacer></v-spacer>
        <!-- menu @ rhs-->
        <v-layout v-if="!isPatientRequest" align-end justify-end>
          <v-tooltip bottom>
            <v-menu v-if="signedIn" slot="activator" class="pr-3">
              <v-toolbar-title slot="activator">
                <v-layout align-end>
                  <v-flex align-self-center style="padding-right: 5px">
                    <span>{{ displayName() }}</span>
                  </v-flex>
                  <v-flex align-self-center>
                    <v-icon large class="white--text">account_circle</v-icon>
                  </v-flex>
                </v-layout>
              </v-toolbar-title>

              <v-list>
                <v-list-tile class="menu-tile"
                             :to="{name: 'clinician_edit',
                                    params: {clinicianUsername: username()}
                                    }"
                >
                  <v-list-tile-title>My Profile</v-list-tile-title>
                </v-list-tile>
                <v-list-tile class="menu-tile" :to="{name: 'my_subscription'}">
                  <v-list-tile-title>My Subscription</v-list-tile-title>
                </v-list-tile>
                <v-list-tile class="menu-tile" :to="{name: 'reset_password_private'}">
                  <v-list-tile-title>Reset Password</v-list-tile-title>
                </v-list-tile>
                <v-list-tile class="menu-tile" @click="doSignOut">
                  <v-list-tile-title>Sign Out</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
            <span>User</span>
          </v-tooltip>
          <v-tooltip bottom>
            <v-menu left slot="activator">
              <v-toolbar-title slot="activator">
                <v-layout align-end>
                  <v-flex align-self-center>
                    <v-icon large class="white--text">help</v-icon>
                  </v-flex>
                </v-layout>
              </v-toolbar-title>

              <v-list>
                <v-list-tile class="menu-tile"
                             href="https://mysymptoms.zendesk.com/hc/en-us/categories/360002243960-mySymptoms-Clinic-User-Guide"
                             target="_blank">
                  <v-list-tile-title>User Guide</v-list-tile-title>
                </v-list-tile>
                <v-list-tile class="menu-tile" :to="{name: 'terms_of_use'}">
                  <v-list-tile-title>Terms of Use</v-list-tile-title>
                </v-list-tile>
                <v-list-tile class="menu-tile" :to="{name: 'privacy_policy'}">
                  <v-list-tile-title>Privacy Policy</v-list-tile-title>
                </v-list-tile>
                <v-list-tile class="menu-tile" :to="{name: 'cookie_policy'}">
                  <v-list-tile-title>Cookie Policy</v-list-tile-title>
                </v-list-tile>
                <v-list-tile class="menu-tile" :to="{name: 'about'}">
                  <v-list-tile-title>About</v-list-tile-title>
                </v-list-tile>
                <v-list-tile class="menu-tile" :to="{name: 'support'}">
                  <v-list-tile-title>Support</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                    name="beta_trial_info"
                    v-if="inBetaTrial"
                    class="menu-tile"
                    :to="{name: 'clinician_beta_info'}"
                    exact>
                  <v-list-tile-title>Beta Trial Info</v-list-tile-title>
                </v-list-tile>
                <!--<v-list-tile :to="{name: 'report_issue'}">-->
                <!--<v-list-tile-title>Report Issue</v-list-tile-title>-->
                <!--</v-list-tile>-->
                <!--<v-list-tile :to="{name: 'documentation'}">-->
                <!--<v-list-tile-title>Documentation</v-list-tile-title>-->
                <!--</v-list-tile>-->
              </v-list>
            </v-menu>
            <span>Help</span>
          </v-tooltip>
        </v-layout>
      </v-toolbar>
      <v-content>

        <!-- session timeout warning - displays when user has been inactive and is about to be logged out -->
        <v-container fluid>
          <div class="text-xs-center">
            <v-dialog v-model="showTimeoutWarning" persistent width="500">
              <v-card>
                <v-card-title class="headline grey lighten-2" primary-title> Timeout
                </v-card-title>
                <v-card-text>
                  Your session will timeout in {{ countdownLeft }} secs. Would you like to
                  continue?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" flat @click="handleTimeoutNo"> No</v-btn>
                  <v-btn color="primary" flat @click="handleTimeoutYes"> Yes</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <!-- displays messages from Logger.error etc -->
          <logger-component class="logger-display"></logger-component>

          <!-- the main content page as determined by Router -->
          <router-view/>

          <!-- Accept Cookies popup -->
          <v-layout row justify-center>
            <v-dialog v-model="showCookiePopup" persistent max-width="290">
              <v-card>
                <v-card-title class="headline">Accept Cookies</v-card-title>
                <v-card-text>
                  This site uses cookies. Some of them are essential while other help us improve
                  your experience. Read the details in our Cookie Policy
                  <router-link :to="{name: 'cookie_policy'}" target="_blank">here.</router-link>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn flat @click="showCookiePopup=false">I Accept</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>

          <!--          intro screens -->
          <v-layout row justify-center>
            <div class="text-center">
              <v-dialog
                  :value="showIntro"
                  width="750"
                  persistent
              >
                <v-card>
                  <v-card-title
                      class="headline grey lighten-2"
                      primary-title
                  >
                    mySymptoms Clinic Introduction
                  </v-card-title>

                  <v-card-text v-if="introTextNum === 1">
                    <p>Welcome to mySymptoms Clinic!
                    <p/>
                    <p>Here's a quick overview of the main features to help you get started. Access them from the left
                      hand navigation panel: </p>

                    <p>
                      1. Go to <b>Notify Patients</b> to email instructions to your patients describing how they share
                      their diary with you.
                    </p>
                    <img src="@/assets/notify_nav.jpg"/>
                  </v-card-text>

                  <v-card-text v-if="introTextNum === 2">
                    <p>
                      2. Go to <b>Invitations</b> page to view any diary sharing invites from your patients.
                    </p>
                    <img src="@/assets/invitations_nav.jpg"/>
                  </v-card-text>

                  <v-card-text v-if="introTextNum === 3">
                    <p>3. Go to <b>Patients</b> to view your patients' diaries.</p>
                    <img src="@/assets/patients_nav.jpg"/>
                  </v-card-text>

                  <v-card-text v-if="introTextNum === 4">
                    <p>
                      For more detail on how the software works, see the <a
                        href="https://mysymptoms.zendesk.com/hc/en-us/articles/5655387160594-mySymptoms-Clinic-Getting-Started-Guide"
                        target="_blank">Getting Started Guide</a>.
                      This is part of the User Guide, accessible from the Help (?) menu at the top of the screen.
                    </p>
                    <img src="@/assets/help_nav.jpg"/>
                  </v-card-text>

                  <v-card-text v-if="introTextNum === 5">
                    <p>
                      You can replay this introduction any time by clicking 'Replay Intro'.
                    </p>
                    <img src="@/assets/replay_nav.jpg"/>
                    <p>
                      If you have any questions for feedback, please contact us at <a
                        href="mailto:support@mysymptoms.net">support@mysymptoms.net</a>.
                    </p>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        flat
                        @click="setIntroShown()"
                    >
                      <span v-if="onFinalIntroText">
                        Done
                      </span>
                      <span v-else>
                        Skip
                      </span>
                    </v-btn>
                    <v-btn
                        v-if="!onFinalIntroText"
                        color="primary"
                        flat
                        @click="incrementIntroText()"
                    >
                      Next
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-layout>

          <v-layout row justify-center>
            <!--                height="500"-->
            <v-dialog
                :value="showMicroSurvey"
                persistent
                width="640"
            >
              <v-flex xs12>

                <v-card>
                  <v-card-title>
                    <!--                    <h2>Could we improve mySyptoms Clinic?</h2>-->
                    <h2>Micro Survey</h2>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="microSurveyQuestion = ''">
                      <v-tooltip bottom>
                        <v-icon
                            slot="activator"
                        >
                          close
                        </v-icon>
                        <span>Close</span>
                      </v-tooltip>
                    </v-btn>
                  </v-card-title>
                  <!--                width="640" height="382" frameborder="0" marginheight="0" marginwidth="0">Loading…-->
                  <v-card-text class="pt-0 pb-2">
                    <p class="pb-2">
                      <!--                      We wanted to take a moment to check in with you ... how are you getting on with mySymptoms Clinic? do you have any-->
                      <!--&lt;!&ndash;                      How can we improve mySymptoms Clinic. We would love to hear your thoughts, feedback, praise, criticism, feature requests etc.&ndash;&gt;-->
                      <!--                      thoughts, feedback, praise, criticism, feature requests etc?-->
                      {{ microSurveyQuestion }}
                    </p>

                    <!--                  <iframe-->
                    <!--                      src="https://docs.google.com/forms/d/e/1FAIpQLScOak7vymIPVd_Bqd9rEoQJ4qXoa1kjFSQHJG0b-Mxn1_pv0g/viewform?embedded=true"-->
                    <!--                      width="100%" height="500px" frameborder="0" marginheight="0" marginwidth="0">Loading…-->
                    <!--                  </iframe>-->
                    <v-form ref="form" v-model="formValid">
                      <v-textarea
                          label="comments etc ..."
                          persistant-hint
                          v-model="microSurveyResult"
                          box
                          :rules="[lenRule]"
                      >

                      </v-textarea>
                    </v-form>
                    <p>You can also email us your feedback at any time at <a href="mailto:clinic@mysymptoms.net"
                                                                             target="_blank">clinic@mysymptoms.net</a>.
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        flat
                        @click="submitMicroSurveyDontAskMeAgain()"
                    >
                      Don't ask me again
                    </v-btn>
                    <v-btn
                        color="primary"
                        flat
                        @click="submitMicroSurveyAskMeLater()"
                    >
                      Ask me later
                    </v-btn>
                    <v-btn
                        color="primary"
                        flat
                        @click="submitMicroSurveyResponse()"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-dialog>
          </v-layout>
        </v-container>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import Logger from './utils/logger-utils'
import LoggerComponent from './components/LoggerComponent.vue'
import Session from './utils/session.js'
import Loader from './components/Loader.vue'
import ClinicianAccountService from './utils/clinician-account-service'
import MicroSurveyService from "./utils/micro-survey-service";
import {checkProperties} from './utils/helpers'
import VError from 'verror'
import moment from 'moment'
import {EventBus} from "./utils/event-bus"
// note we have to import the icons manually as the 'article' icon is missing from the material library
import patientsImg from './assets/people_outline_white_48dp.svg'
import NotifyPatientsImg from './assets/send_white_48dp.svg'
import PatientInvitationsImg from './assets/inbox_white_48dp.svg'
import NewsImg from './assets/article_white_48dp.svg'
import PlansImg from './assets/local_offer_white_48dp.svg'
import FeedbackImg from './assets/message_white_48dp.svg'
import AccountService from "./utils/account-service";
import Cookies from 'js-cookie'
import {len2048Rule} from "./utils/rules";
import _ from "lodash"
// import AccountServcie from "./utils/account-service";

// when the session has expired the user is shown a window asking if he wants to carry on. If he doesn't click Yes
// within a Timer period, he is automatically signed out
const countdownTimerStart = 15 // seconds
let countdownTimer = null
const dateFormat = "DD MMM YYYY kk:mm"
const pendingInviteCheckerIntervalSecs = 5 * 60 // 5 mins
const idleIntervalSecs = 30 * 60 // 10 mins
const acceptCookieStorageKey = "mySymptomsCookiesAccepted"
const shownIntroCookieName = "mySymptomsShownIntro"

let paddleScript = document.createElement('script')
paddleScript.setAttribute('src', 'https://cdn.paddle.com/paddle/paddle.js')
paddleScript.async = true
document.head.appendChild(paddleScript)

export default {
  data() {
    return {
      mini: false,
      countdownLeft: countdownTimerStart,
      pendingInviteChcker: null,
      tokenRenewTimer: null,
      inactivityCheckerTimeout: null,
      isUserIdle: false,
      // Flag to show whether the user has accepted cookies.
      // Might seem odd to set default to true, but setting to false causes a brief flash of the popup before
      // while the cookies are queried for a stored value.
      cookiesAccepted: true,
      // the mode used on the build command line
      mySymptomsMode: '',
      introShown: false,
      introTextNum: 1,
      totalNumIntroTexts: 5,
      hasPatients: null,
      microSurveyShown: false,
      microSurveyQuestion: "",
      microSurveyQuestionId: null,
      microSurveyResult: "",
      formValid: true,
    }
  },
  components: {
    LoggerComponent,
    Loader
  },
  computed: {
    showMicroSurvey() {
      // return this.signedIn && !this.microSurveyShown
      // this.getMicroSurveyQuestion(this.username())
      return this.signedIn && (this.microSurveyQuestion !== "")
    },

    onFinalIntroText() {
      return this.introTextNum === this.totalNumIntroTexts;
    },
    // only show the intro if we don't have any patients and the intro hasn't been previously dismissed
    showIntro() {
      return this.hasPatients != null & !this.hasPatients && !this.introShown;
    },

    showCookiePopup: {
      get() {
        const onCookiePolicyPage = this.$route.name === 'cookie_policy'
        return !this.cookiesAccepted && !onCookiePolicyPage
      },
      set(newVal) {
        this.cookiesAccepted = !newVal
      }
    },
    lastActivityTime() {
      return this.$store.state.lastActivityTime
    },
    signedIn() {
      return Session.isSignedIn()
    },
    isSysAdminUser() {
      return _.includes(this.$store.state.authorities, "SYS_ADMIN")
    },
    isClinician() {
      return _.includes(this.$store.state.authorities, "CLINICIAN")
    },
    navItems() {
      // if we have any pending requests, add e.g. '(1)' the Patient Invitations label in the side nav bar
      const numPendingInvitations = this.$store.state.pendingPatientInvitations.length
      const pendingReqsIndicator = numPendingInvitations === 0 ? '' : ` (${numPendingInvitations})`
      const newsAlert = this.$store.state.unreadNews ? ' new!' : ''
      return [
        {
          title: 'Patients',
          icon: patientsImg,
          to: {name: "patient_management", params: {clinicianUsername: `${this.$store.state.username}`}}
        },
        {title: `Invitations${pendingReqsIndicator}`, icon: PatientInvitationsImg, to: {name: 'patient_invitations'}},
        {title: 'Notify Patients', icon: NotifyPatientsImg, to: {name: 'notify_patients'}},
        {title: 'Plans', icon: PlansImg, to: {name: 'plans'}},
        {title: 'News', alert: newsAlert, icon: NewsImg, to: {name: 'news'}},
        {title: 'Feedback', icon: FeedbackImg, to: {name: 'feedback'}},
      ]
    },

    // we show the timeout warning modal when the user is signed in, the user is inactive,
    // and we're still counting down
    showTimeoutWarning: {
      get() {
        const isSignedIn = Session.isSignedIn()
        const stillCountingDown = (this.countdownLeft > 0)
        const showWarning = stillCountingDown && isSignedIn && this.isUserIdle
        // Logger.debug(`showWarning: ${showWarning}, isSignedIn: ${isSignedIn}, stillCountingDown: ${stillCountingDown}, userIdle: ${this.isUserIdle}`)
        return showWarning
      },
      // set() is required as we're using <b-modal> with v-model="showTimeoutWarning". b-modal calls set()
      // but we can ignore the call
      set() {
      }
    },

    // we want to show the mode in the header of the page the distribution has been built for anything other than production
    showMode() {
      return this.mySymptomsMode !== "production"
    },

    inBetaTrial() {
      const ret = process.env.VUE_APP_BETA_TRIAL === "true" || false
      return ret
    },

    isPatientRequest() {
      return this.$route.meta.patientReq;
    }
  },
  methods: {
    lenRule: len2048Rule,

    submitMicroSurveyDontAskMeAgain() {
      // submit empty answer so we don't get asked again
      this.submitAnswer("<declined>")
    },

    submitMicroSurveyAskMeLater() {
      // we don't do submit an answer, so the user will get the question again when they next log in
      this.microSurveyQuestionId = null
      this.microSurveyQuestion = ""
      this.microSurveyResult = ""
    },

    submitMicroSurveyResponse() {
      if (this.formValid) {
        this.submitAnswer(this.microSurveyResult)
      }
    },

    // answer might be empty if the user clicks 'Don't Ask Me Again' ... we submit an empty answer
    submitAnswer(answer) {

      MicroSurveyService.submitAnswer(
          this.$store.state.username,
          this.microSurveyQuestionId,
          this.microSurveyQuestion,
          answer)

      this.microSurveyQuestionId = null
      this.microSurveyQuestion = ""
      this.microSurveyResult = ""

    },

    getMicroSurveyQuestion() {
      MicroSurveyService.getUnanswered(this.$store.state.username).then(res => {
        if (res.data != null) {
          this.microSurveyQuestionId = res.data.id
          this.microSurveyQuestion = res.data.question
        }
      })
    },
    incrementIntroText() {
      this.introTextNum = this.introTextNum + 1
    },

    setIntroShown() {
      this.introShown = true
      localStorage.setItem(shownIntroCookieName, "true")
    },

    replayIntro() {
      this.introShown = false
      this.introTextNum = 1
    },
    // keep track of the last time we had any user input, to avoid updating too frequently we dont' update more
    // than once per second. Also, don't log while the Timeout window is open as moving the mouse to click on the
    // Yes or No buttons will dismiss the window.
    logActivity() {
      const threshold = moment(this.$store.state.lastActivityTime).add(1, 'seconds')
      const now = moment()

      if (now.isAfter(threshold) && !this.showTimeoutWarning) {
        // setting the lastActivityTime in the store so it gets broadcast to other tabs
        this.$store.commit('setLastActivityTime', {value: now.toISOString(), broadcast: true})
      }
    },

    setUserActive() {
      this.$store.commit('setLastActivityTime', {value: moment().toISOString(), broadcast: true})
    },

    username() {
      return this.$store.state.username
    },

    // the name of the currently logged in user
    displayName() {
      return this.$store.state.displayName
    },

    getAccountInfoFromServer() {

      // return a promise
      return AccountService.getAccountInfo(this.$store.state.username).then((resp) => {

        let dispName = ''

        checkProperties(resp, 'data')
        checkProperties(resp.data, ['firstName', 'username', 'authorities'])

        // the firstName should be populated as it's a compulsory field on sign-up, however if it's blank
        // fall back to the username
        if (resp.data.firstName !== '') {
          dispName = resp.data.firstName
        } else if (resp.data.username !== '') {
          dispName = resp.data.username
        } else {
          // if the username is blank, we've got big problems
          throw new Error("username is empty")
        }
        this.$store.commit("setDisplayName", {value: dispName, broadcast: false})

        this.$store.commit("setAuthorities", {value: resp.data.authorities, broadcast: false})

      })
    },

    /**
     * update the subscription info held in the store
     */
    getSubsInfo() {

      ClinicianAccountService.getActiveSubscription(this.$store.state.username).then((resp) => {
        // const tz = moment.tz.guess(true);

        const plan = {
          name: resp.data.subscriptionPlanName,
          displayName: resp.data.subscriptionPlanDisplayName,
          numClients: resp.data.numClients ? resp.data.numClients : null,  // use null to represent unlimited clients
          isPremium: resp.data.premium,
          status: resp.data.status,
          cancellationEffectiveDate: resp.data.cancellationEffectiveDate,
          cancelUrl: resp.data.cancelUrl ? resp.data.cancelUrl : "",
          updatePaymentMethodUrl: resp.data.updateUrl ? resp.data.updateUrl : "",
          currency: resp.data.currency ? resp.data.currency : "",
          price: resp.data.price ? resp.data.price : 0,
          nextBillDate: resp.data.nextBillDate,
          createdAt: resp.data.createdAt,
          paymentMethod: resp.data.paymentMethod,
          cardType: resp.data.cardType,
          lastFourDigits: resp.data.lastFourDigits,
          expiryDate: resp.data.expiryDate,
          paymentFailed: resp.data.paymentFailed ? resp.data.paymentFailed : '',
          retryNumber: resp.data.retryNumber ? resp.data.retryNumber : '',
          nextRetryDate: resp.data.nextRetryDate,
          pausedFrom: resp.data.pausedFrom,
          pausedAt: resp.data.pausedAt,
          pausedReason: resp.data.pausedReason,
          paddleSubscriptionId: resp.data.paddleSubscriptionId ? resp.data.paddleSubscriptionId : 0,
          paddlePlanId: resp.data.paddlePlanId
        }

        this.$store.commit("setPlan", {value: plan, broadcast: true})
      }).catch(err => {
        Logger.error(new VError(err, 'getSubsInfo failed'), {})
      })
    },

    isSubscriptionPaused() {
      return this.$store.state.plan.status === 'paused'
    },

    isPastDue() {
      return this.$store.state.plan.status === 'past_due'
    },
    doSignOut() {
      // note signOut triggers the this.watch on signedIn to redirect to signin page
      Session.signOut()
    },

    handleTimeoutYes() {
      this.setUserActive()
    },

    handleTimeoutNo() {
      this.doSignOut()
    },

    // start the countdown, warning the user he will be signed out in 10 seconds
    countdown() {
      const that = this
      countdownTimer = setTimeout(() => {
        if (that.countdownLeft === 1) {
          // countdown finished
          // Logger.debug("countdown expired, signing out and resetting countdown")
          that.countdownLeft = countdownTimerStart
          Session.signOut()
        } else {
          // countdown not yet finished, decrement the count and wait some more
          that.countdownLeft -= 1
          // Logger.debug(`countdownLeft now: ${this.countdownLeft}`)
          that.countdown()
        }
      }, 1000)
    },

    handleCheckForPendingInvitesEvent() {
      const startNum = this.$store.state.pendingPatientInvitations.length
      // the following call re populates the pendingPatientInvitations field in the store
      this.checkForPendingInvites().then(() => {
        // let the user know if we've not found any more
        if (startNum === this.$store.state.pendingPatientInvitations.length) {
          Logger.info('No new invites found.')
        }
      }).catch(err => {
        Logger.error(err, {})
      })
    },

    checkForPendingInvites() {
      return ClinicianAccountService.getPendingInvitations(this.$store.state.username).then(resp => {
        checkProperties(resp, 'data')
        const requests = resp.data.map(request => {
          checkProperties(request, ['patient', 'createdAt'])
          // check to see if we have stored a name for this user in a cookie, note names are stored encoded
          // noinspection JSUnresolvedVariable
          const createdDate = moment(request.createdAt)
          // assume if firstName is set, so is lastName ... enforced at the database level
          const patientRealName = ((request.hasOwnProperty('patientFirstName') && request.patientFirstName !== null)) ?
              request.patientFirstName + " " + request.patientLastName : ""
          // noinspection JSUnresolvedVariable
          return {
            patientUsername: request.patient,
            patientRealName: patientRealName,
            requestDate: createdDate.format(dateFormat),
            requstDateSortable: createdDate.valueOf(), // we need a version of the 'since' field for sorting on
          }
        })
        this.$store.commit('setPendingPatientInvitations', {broadcast: false, value: requests})
      }).catch(err => {
        Logger.error(err, {})
      })
    },

    checkForPatients() {
      ClinicianAccountService.getPatients(this.$store.state.username).then(resp => {
        this.hasPatients = resp.data.length > 0
      }).catch(err => {
        Logger.error(err, {})
      })
    },
    checkForUnreadNews() {
      ClinicianAccountService.getUnreadNews(this.$store.state.username).then(res => {
        const unreadNews = res.data.unreadNews;
        this.$store.commit('setUnreadNews', {broadcast: true, value: unreadNews})
      }).catch(err => {
        Logger.error(err, {})
      })
    },

    // kick off a background process to run periodically to download the pending patient invites to the Store
    runPendingInviteChecker() {
      const that = this
      this.checkForPendingInvites()
      // recursively run again after timeout
      this.pendingInviteChcker = setTimeout(() => {
        that.runPendingInviteChecker()
      }, pendingInviteCheckerIntervalSecs * 1000)
    },

    stopPendingInviteChecker() {
      clearTimeout(this.pendingInviteChcker)
    },

    doSignIn() {
      //
      //     // populates the account info store
      this.getAccountInfoFromServer().then(() => {
        //
        if (this.isSysAdminUser) {
          this.$router.push({path: "clinician_management"})
        } else {
          //
          // populate subscription info in the store
          this.getSubsInfo()
          //
          //         // are there any new versions of the policy docs for the user to accept? If so, go to the AcceptPolicyDocs
          //         // page
          return AccountService.getPolicyDocsToAccept(this.username()).then(res => {
            // res format: {
            // "data": [
            //   {
            //     "id": 2,
            //     "docType": "t_and_c",
            //     "docURL": "someurl",
            //     "version": 2,
            //     "effectiveFrom": "2020-06-19"
            //   }
            // ],
            //
            // irrespective of whether we have policy docs to accept, we need to starting PendingInviteChecker running
            this.runPendingInviteChecker()

            this.checkForUnreadNews();

            this.checkForPatients();

            this.getMicroSurveyQuestion()
            //
            //           // if the user was trying to access a protected resource before he hit the signin screen this will have
            //           // been saved as the redirect query param by the navigational guard, if not, set to a default
            const redirectPath = this.$route.query.redirect || '/'
            //
            if (res.data.length > 0) {
              this.$router.push({
                name: 'accept_policy_docs',
                params: {
                  docsToAccept: res.data,
                  redirectPath: redirectPath
                }
              })
            } else {

              // if the user was trying to access a protected resource before he hit the signin screen this will have
              // been saved as the redirect query param by the navigational guard
              this.$router.push({path: redirectPath})
            }
          })
        }
      }).catch(err => {
        Logger.error(err, {})
      })
    }
  },

  watch: {
    // write the 'cookiesAccepted' value to a cookie whenever it changes, note this automatically converts a
    // boolean to string which we need to manually convert back when we read the from the cookie on Mounted
    cookiesAccepted(newVal) {
      Cookies.set(acceptCookieStorageKey, newVal)
    },

    // useful for debugging
    // isUserIdle(newVal, oldVal) {
    //   // Logger.debug(`in isUserIdle watcher, newVal: ${newVal}`)
    // },

    // when the last activity time changes, reset the timer which the flags the user as being idle after a certain period
    lastActivityTime() {
      // Logger.debug(`lastActivityTime updated: ${newVal}, resetting inactivityCheckerTimeout for ${idleIntervalSecs} secs.`)
      if (this.inactivityCheckerTimeout != null) {
        clearTimeout(this.inactivityCheckerTimeout)
      }
      this.isUserIdle = false

      // if the timeout completes, then the user must have been inactive for long enough
      this.inactivityCheckerTimeout = setTimeout(() => {
        // Logger.debug(`user inactive, setting this.isUserIdle = true`)
        this.isUserIdle = true
      }, idleIntervalSecs * 1000)
    },

    // kick off the countdown timer when the showTimeoutWarning computed member becomes true (and hence the popup is
    // shown.
    showTimeoutWarning(newVal,) {
      // Logger.debug(`showTimeoutWarning watcher, newVal: ${newVal}`)
      if (newVal) {
        // dialog has been shown so start the countdown
        this.countdown()

      } else {
        // dialog has been closed (either manually by use to automatically after countdown finished
        clearTimeout(countdownTimer)
        // reset our countdown
        this.countdownLeft = countdownTimerStart
      }
    },

    // watch for changes in the signed-in status. Events that trigger a change in status ..
    //    1) User explicitly signing in or out in this tab
    //    3) The user signing in or out in another tab and this tab receives a status update event
    //    2) The user session timing out and the user being automatically signed out
    signedIn(newVal,) {

      // Logger.debug(`isSignedIn watcher, newVal: ${newVal}`)
      // if we are signing in redirect to either the home page or the page the user was trying to access when he
      // hit the sign-in page
      if (newVal === true) {
        this.doSignIn()
      } else {
        // if the signed-in status has change to false, then redirect all open tabs to the sign-in page.
        this.stopPendingInviteChecker()
        this.$router.push({name: 'signin'})
        this.$store.commit("setDisplayName", {value: '', broadcast: false})
      }
    },
  },

  created() {
    // this event is sent from the Check Now button on the Patient Invites screen
    EventBus.$on('check-for-pending-invites', this.handleCheckForPendingInvitesEvent)
    EventBus.$on('update-subscription-plan', this.getSubsInfo)

    // if the user opens a new tab when already signed in on another, then we need to start the pendingInviteChecker
    // and InactivityChecker here
    if (this.signedIn && this.isClinician) {
      this.runPendingInviteChecker()
    }
  },

  mounted() {
    this.introShown = localStorage.getItem(shownIntroCookieName) === "true"

    const cookieStr = Cookies.get(acceptCookieStorageKey)

    this.cookiesAccepted = cookieStr === "true" || false

    this.mySymptomsMode = process.env.VUE_APP_MYSYMPTOMS_MODE || "Error - Mode Not Set!!"

    // set an initial default display currency base on the browser language. It will be changed later to reflect
    // the country of the user once the log in
    // const lang = LanguageUtils.getLang()
    // this.$store.commit("setDisplayCurrency", {value: 'USD', broadcast: false})

    if (this.isSubscriptionPaused()) {
      Logger.error("Your subscription is Paused. Please update your payment details or contact support to reactive your account.", {
        duration: null
      })
    }
  }
}
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Questrial");

//.application {
//  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
//}

.myFont {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.menu-tile {
  a:hover {
    text-decoration: none;
  }
}

.nav-drawer-tile {
  color: var(--v-secondary-lighten3)
}

.nav-drawer-tile:hover {
  background: var(--v-secondary-lighten1);
  color: var(--v-secondary-darken2)
}

.nav-drawer {
  a:hover {
    text-decoration: none;
  }
}

.nav-icon {
  width: 40px;
}

//.beta_badge {
//  padding-left: 10px;
//  font-size: x-large;
//}

.header_warning {
  background-color: var(--v-error_background-base);
  opacity: 0.87;
  color: var(--v-on_error_background-base);
  padding-left: 3px;
  margin-left: 3px
}
</style>

<style lang="stylus">
$color-pack = false

@import '~vuetify/src/stylus/main'
</style>
