<template>
    <div class="row">
        <!-- left padding to centre the form -->
        <div class="col-lg-3 col-sm-2"></div>

        <div class="col-lg-6 col-sm-8 mysymptoms-form-container">
            <h2 class="text-center">Clinician Profile</h2>

            <v-form ref="form" v-model="valid" @submit="submit">
                <v-text-field
                        v-model="clinicianUsername"
                        label="Username"
                        readonly
                        disabled
                ></v-text-field>
                <v-text-field
                        v-model="email"
                        readonly
                        label="E-mail"
                        disabled
                ></v-text-field>

                <ClinicianCommonFields
                        v-bind:profession.sync='profession'
                        v-bind:professionOther.sync='professionOther'
                        v-bind:title.sync='title'
                        v-bind:titleOther.sync='titleOther'
                        v-bind:firstName.sync='firstName'
                        v-bind:lastName.sync='lastName'
                        v-bind:practiceName.sync='practiceName'
                        v-bind:addressLine1.sync='addressLine1'
                        v-bind:addressLine2.sync='addressLine2'
                        v-bind:city.sync='city'
                        v-bind:state.sync='state'
                        v-bind:postcode.sync='postcode'
                        v-bind:country.sync='country'
                >
                </ClinicianCommonFields>

                <div class="text-xs-center">

                    <v-btn
                            type="submit"
                            color="primary"
                    >
                        Update
                    </v-btn>
                </div>

            </v-form>
        </div>

        <!-- right padding to centre the form -->
        <div class="col-lg-3 col-sm-2"></div>

    </div>
</template>

<script>

  import Logger from '../utils/logger-utils'
  import ClinicianAccountService from '../utils/clinician-account-service'
  import Loader from '../utils/loader-utils'
  import PasswordUtils from '../utils/password-utils'
  import ClinicianCommonFields from "../components/ClinicianCommonFields";
  import {CountryCodes} from '../utils/country-codes'


  export default {
    components: {ClinicianCommonFields},
    name: "Clinician",
    props: ['clinicianUsername'],
    data:
      () => ({
        test: '',
        showPassword: false,
        valid: true,
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        profession: '',
        professionOther: '',
        title: '',
        titleOther: '',
        firstName: '',
        lastName: '',
        practiceName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postcode: '',
        // country: {text: '', value: ''}
        country: ''
      }),
    methods: {
      submit() {
        try {
          if (this.$refs.form.validate()) {

            Loader.start()

            const encryptedPassword = PasswordUtils.encrypt(this.password)

            ClinicianAccountService.updateClinicianAccount(this.username, {
              username: this.username,
              email: this.email,
              password: encryptedPassword,
              profession: this.profession,
              professionOther: this.professionOther,
              title: this.title,
              titleOther: this.titleOther,
              firstName: this.firstName,
              lastName: this.lastName,
              practiceName: this.practiceName,
              addressLine1: this.addressLine1,
              addressLine2: this.addressLine2,
              city: this.city,
              state: this.state,
              zip: this.postcode,
              // country: this.country.value
              country: this.country
            }).then(resp => {
              Loader.stop()
              Logger.info('Profile Updated.')
              this.$router.push({name: 'home'})
            }).catch(err => {
              Loader.stop()
              Logger.error(err)
            })
          }
        } catch (e) {
          Logger.error(e)
        }
      },
    },

    created() {
      ClinicianAccountService.getClinicianAccountInfo(this.clinicianUsername).then(res => {
        // const countryText = CountryCodes[res.data.country]
        this.username = res.data.username
        this.email = res.data.email
        this.profession = res.data.profession
        this.professionOther = res.data.professionOther
        this.title = res.data.title
        this.titleOther = res.data.titleOther
        this.firstName = res.data.firstName
        this.lastName = res.data.lastName
        this.practiceName = res.data.practiceName
        this.addressLine1 = res.data.addressLine1
        this.addressLine2 = res.data.addressLine2
        this.city = res.data.city
        this.state = res.data.state
        this.postcode = res.data.zip
        this.country = res.data.country
        // this.country = { value: res.data.country, text: CountryCodes[res.data.country] }
        // this.country.value = res.data.country
      }).catch(err => {
        Logger.error(err)
      })
    }
  }
</script>
