import {emailOK, usernameOK} from "./regexps";

const emailOKRule = v => emailOK.test(v) || 'E-mail must be valid'

const usernameOKRule = v => usernameOK.test(v) || "Must be between 6 to 20 characters, comprising only lowercase letters, numbers, _, - and ."

// used by the signin screen. It's a weaker form of the usernameOKRule ... we want to guard against the user logging
// in with a username that differs by case from the one the register with. Spring will allow it, but our api security
// doesn't.
const noUppercaseRule = v => v.toLowerCase() === v || "Uppercase letters not allowed"

const requiredRule = v => !!v || `Field is required.`

const len64Rule = v => v.length <= 64 || "Must be less than 64 characters."
const len2048Rule = v => v.length <= 2048 || "Must be less than 2048 characters."
const usernameNotEmailRule = v => !emailOK.test(v) || "It looks like you have entered an email address instead of your username"

export {emailOKRule, usernameOKRule, requiredRule, len64Rule, len2048Rule, noUppercaseRule, usernameNotEmailRule}
